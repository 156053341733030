import { gql } from "@apollo/client";

export const Discount = gql`
  query GetDiscount {
    discount {
data {
    attributes {
      percentage,
      text
    }
  }  
}
  }
`;
