import { gql } from '@apollo/client';

export const Shipping = gql`
  query getShipping {
    shipping{
        data{
          attributes{
            metro
          }
        }
    }
  }
`;
