import { createContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Discount } from "src/graphql/query/discount";
import { Shipping } from '../graphql/query/shipping';

export const ParametersContext = createContext([]);

const ParametersContextProvider = ({ children }) => {
    const { data:dataDiscount, loading:loadingDiscount } = useQuery(Discount);
    const { data:dataShipping, loading:loadingShipping } = useQuery(Shipping);

    const discount = useMemo(() => dataDiscount?.discount.data?.attributes, [dataDiscount])
    const shippingMetro = useMemo(() => dataShipping?.shipping.data?.attributes.metro ?? 900, [dataShipping])

    return (
        <ParametersContext.Provider value={
            { discount,
              shippingMetro,
              loading:  (loadingDiscount || loadingShipping) }}>
            {children}
        </ParametersContext.Provider>
    );
};

export default ParametersContextProvider;
