import { gql } from "@apollo/client";

export const Products = gql`
  query GetProducst {
    products (pagination: { start: 0, limit: -1 }) {
      data {
        id
        attributes {
          price
          ratings
          sku
          stock
          slug
          arrivingSoon
          presentation
          unitsper
          category {
            data {
              id
              attributes {
                name
                label
              }
            }
          }
          sub_category{
            data {
              id
              attributes {
                name
                label
              }
            }
          }
          title
          description
          images {
            data {
              id
              attributes {
                url
              }
            }
          }
          videos {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;


export const SingleProduct = gql`
  query GetProduct($prodId: ID!) {
    product (id:$prodId) {
        data {
            id
            attributes {
              publishedAt
              price
              ratings
              sku
              stock
              slug
              arrivingSoon
              presentation
              unitsper
              category {
                data {
                  id
                  attributes {
                    name
                    label
                  }
                }
              }
              sub_category{
                data {
                  id
                  attributes {
                    name
                    label
                  }
                }
              }
              
              title
              description
              images {
            data {
              id
              attributes {
                url
              }
            }
          }
            }
          }
        }
      }
 
`;
