import { createContext, useContext, useMemo } from 'react';
import { useQuery } from "@apollo/client";
import { Products } from "src/graphql/query/products";
import { formatProducts } from "@utils/formatProducts";
import { ParametersContext } from '@global/ParametersContext';

export const ProductsContext = createContext([]);

const ProductsContextProvider = ({ children }) => {
  const { data, loading } = useQuery(Products, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
  const parametersContext = useContext(ParametersContext);
  const productsData = useMemo(() => formatProducts(data, parametersContext?.discount?.percentage ?? null), [data])

  return (
    <ProductsContext.Provider value={{ products: [...productsData], loading }}>
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextProvider;
