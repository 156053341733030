import { AppConfig } from "src/app-config";
import slugify from 'slugify';

export const buildProduct = (p, discount, hasQty=false) => {
  const { attributes } = p;
  const prod = {
    id: p.id,
    sku: attributes.sku,
    name: attributes.title,
    ratings: attributes.ratings,
    price: attributes.price,
    priceBox: attributes.price * (attributes.unitsper ?? 1),
    stock: attributes.stock,
    slug: slugify(`${p.id} ${attributes.title}`),
    arrivingSoon: attributes.arrivingSoon ?? false,
    discount: discount ?? null,
    discountDuration: null,
    badge: "",
    totalSale: 56,
    vendor: "",
    skin: "",
    type: ["featured"],
    categories:
      attributes?.category?.data ? [{
        name: attributes?.category?.data.attributes.name,
        label: attributes?.category?.data.attributes.label,
      }] : [],
    subCategory: attributes?.sub_category?.data ? {
      name: attributes?.sub_category?.data.attributes.name,
      label: attributes?.sub_category?.data.attributes.label,
    } : null,
    tags:  [],
    thumb: `${AppConfig.api_url}${attributes?.thumb?.data?.attributes?.url}`,
    thumbs:
      attributes?.images?.data.map(
        (c) => `${AppConfig.api_url}${c.attributes.url}`
      ) ?? [],
    previewImages:
      attributes?.images?.data.map(
        (c) => `${AppConfig.api_url}${c.attributes.url}`
      ) ?? [],
    excerpt: attributes.description,
    description: attributes.description,
    variations: [],
    presentation: attributes.presentation ?? 'Unidad',
    unitsper: attributes.unitsper ?? 1,
    unit: attributes.presentation === 'Caja' ? 'M2' : ''
  };
  if(hasQty) {
    prod.quantity = attributes?.quantity ?? null;
  }

  return prod
};

export function formatProducts(productsRaw, discount) {
  if (
    productsRaw &&
    productsRaw?.products &&
    productsRaw?.products?.data?.length !== 0
  ) {
    const bp = p => buildProduct(p, discount,  !!p.attributes.quantity);
    return productsRaw.products.data.map(bp);
  }

  return [];
}

export function formatProductGroup(productGroup) {
  const { attributes } = productGroup;
  return {
    id: productGroup.id,
    thumb: `${AppConfig.api_url}${attributes.thumb.data.attributes.url}`,
    products: attributes.products.map((p) => ({
      ...p,
      product: buildProduct(p.product.data),
    })),
  };
}
export function formatProductGroups(groupsRaw) {
  if (
    groupsRaw &&
    groupsRaw?.productGroups &&
    groupsRaw?.productGroups?.data?.length !== 0
  ) {
    return groupsRaw.productGroups.data.map(formatProductGroup);
  }

  return [];
}
